import React, { useEffect } from 'react'
import '../dashboard/UserList.scss'
import StarIcon from "../../assets/images/StarIcon.png"
import GreenTick from "../../assets/images/GreenTick.png"
import InvestBox from '../../components/investbox/InvestBox'
import InvestmentCard from '../../components/investmentCard/InvestmentCard'
import BankSecure from "../../assets/images/BankSecure.png"
import { useLocation, useNavigate } from 'react-router-dom'
import { fetchOneViewData, fetchUserDetails, fetchUserInvestments } from '../slice'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/loader/Loader'

function UserList() {
    const location = useLocation();
    const rowData = location.state.rowData;
    const dispatch = useDispatch()
    const { isLoading, userInvestments, oneViewData, userList } = useSelector(state => state.adminSlice)
    
    useEffect(() => {
        let queryParam = `?userId=${rowData?.id}`;
        dispatch(fetchUserInvestments(queryParam))
    }, [dispatch]);

    useEffect(() => {
        let queryParam = `?userId=${rowData?.id}`;
        dispatch(fetchUserDetails(queryParam))
    }, [dispatch]);

    useEffect(() => {
        let queryParam = `?userId=${rowData?.id}`;
        dispatch(fetchOneViewData(queryParam))
    }, [dispatch]);

    const investdata = [
        {
            imagesource1: StarIcon,
            boxhead1: 'KYC',
            boxhead2: userList?.data?.rows[0]?.userStatus == 'isAadharKycVerify' ? 'Done' : 'Pending',
            showImage: userList?.data?.rows[0]?.userStatus == 'isAadharKycVerify' ? true : false,
            imagesource2: GreenTick
        },
        {
            imagesource1: StarIcon,
            boxhead1: 'Nominee',
            boxhead2: 'Pending',
            showImage: false
        },
        {
            imagesource1: StarIcon,
            boxhead1: 'One View',
            showImage: oneViewData?.data?.sessionId == null ? false : true,
            boxhead2: oneViewData?.data?.sessionId == null ? 'Pending' : 'Done',
            imagesource2: GreenTick
        },
        {
            imagesource1: StarIcon,
            boxhead1: 'Risk Profile',
            boxhead2: 'Done',
            showImage: true,
            imagesource2: GreenTick
        }
    ]

    const balancedata = [
        {
            imagesource1: BankSecure,
            boxhead1: 'Bank Balance',
            boxtitle1: 'Current Balance',
            boxsubtitle1: '₹505.30',
            boxtitle2: 'Allocated',
            boxsubtitle2: '30%',
        },
        {
            imagesource1: BankSecure,
            boxhead1: 'Mutual Fund Balance',
            boxtitle1: 'Current Balance',
            boxsubtitle1: '₹505.30',
            boxtitle2: 'Allocated',
            boxsubtitle2: '30%',
        },
        {
            imagesource1: BankSecure,
            boxhead1: 'Bonds & Debenture',
            boxtitle1: 'Current Balance',
            boxsubtitle1: '₹505.30',
            boxtitle2: 'Allocated',
            boxsubtitle2: '30%',
        },
        {
            imagesource1: BankSecure,
            boxhead1: 'Bonds & Debenture',
            boxtitle1: 'Current Balance',
            boxsubtitle1: '₹505.30',
            boxtitle2: 'Allocated',
            boxsubtitle2: '30%',
        },
        {
            imagesource1: BankSecure,
            boxhead1: 'NPS',
            boxtitle1: 'Current Balance',
            boxsubtitle1: '₹505.30',
            boxtitle2: 'Allocated',
            boxsubtitle2: '30%',
        },
        {
            imagesource1: BankSecure,
            boxhead1: 'Insurance Investment/...',
            boxtitle1: 'Current Balance',
            boxsubtitle1: '₹505.30',
            boxtitle2: 'Allocated',
            boxsubtitle2: '30%',
        },
        {
            imagesource1: BankSecure,
            boxhead1: 'Bonds & Debenture',
            boxtitle1: 'Current Balance',
            boxsubtitle1: '₹505.30',
            boxtitle2: 'Allocated',
            boxsubtitle2: '30%',
        },
    ]

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/users');
    };

    return (
        <>
            {isLoading && <Loader />}
            <div className='dashboard main-Page-css userlist-css'>
                <div className='bluebox box10 mb-4' onClick={handleClick}>
                    <p className='backtext'>Back</p>
                </div>
                <div className='main-page-title'>User list</div>
                <div className='row'>
                    <div className='col-4'>
                        <p className='labelhead'>Full Name</p>
                        <input type='text' disabled defaultValue={rowData.firstname} className='inputbox' />
                    </div>
                    <div className='col-4'>
                        <p className='labelhead'>Email</p>
                        <input type='email' disabled defaultValue={rowData.email} className='inputbox' />
                    </div>
                    <div className='col-4'>
                        <p className='labelhead'>Contact Number</p>
                        <input type='text' disabled defaultValue={rowData.mobile} className='inputbox' />
                    </div>
                </div>
                <div className='borderline'></div>
                <div className='row'>
                    {investdata?.map((data, index) => (
                        <div className='col-4'>
                            <InvestBox key={index} {...data} />
                        </div>
                    ))}
                </div>
                <div className='borderline'></div>
                <div className='mainbox'>
                    <div className='col-4'>
                        <InvestBox
                            imagesource1={StarIcon}
                            boxhead1='Investment'
                            boxhead2={userInvestments?.holds?.length > 0 ? 'Done' : 'Pending'}
                            showImage={userInvestments?.holds?.length > 0 ? true : false}
                            imagesource2={GreenTick}
                        />
                    </div>
                    <p className='investhead'>Types of investments</p>
                    <div className='row mt-3'>
                        {userInvestments?.holds?.length > 0 ? (
                            userInvestments.holds.map((data, index) => (
                                <div key={index} className='col-4'>
                                    <InvestmentCard
                                        imagesource1={BankSecure}
                                        boxhead1={data?.type}
                                        boxtitle1='Current Balance'
                                        boxsubtitle1={data?.value.toFixed(2)}
                                        boxtitle2='Allocated'
                                        boxsubtitle2={`${data?.percentage.toFixed(2)} %`}
                                    />
                                </div>
                            ))
                        ) : (
                            <p className='no-data-available'>No data available</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserList