import React, { useState } from 'react';
import './CustomTable.scss'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Search from '../../assets/images/searchIcon.png'
import DateRange from '../../assets/images/date-range.png'
import Report from '../../assets/images/report-img.png'
import addIcon from '../../assets/images/add-icon.png'
import DeleteIcon from "../../assets/images/DeleteIcon.png"
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PageNavLeft from '../../assets/images/pagination_left.png';
import PageNavRight from '../../assets/images/pagination_right.png';

function CustomTable({ data, headers, showStatusColumn, showActions, actionsHeaderText, showSearchBar, showTableImg, ImgHeaderText, showActions2, actionsHeaderText2, onViewClick, expandedRow, showSearchBarButtons, showView, showAddIcon, onAddClick, showBankIcon, showDeleteIcon, showIcon, showtablecss, showPagination, onPageChange, getLimit = () => { }, currentPage, totalItems, totalPages, onSearchChange, showAddButton, onAddButtonClick, showEditButton, onEditClick, onDeleteClick }) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const rowsPerPageOptions = [10, 25, 50];
    const [limit, setLimit] = useState(10);
    const [searchText, setSearchText] = useState('');
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
        onSearchChange(e.target.value); // update the parent component with the new search text
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handlePageChange = (page) => {
        onPageChange(page);
    };
    const onRowsPerPageChange = (rows) => {
        setLimit(rows)
        getLimit(rows)
        handleClose();
    };

    const startIndex = (currentPage - 1) * limit + 1;
    const endIndex = Math.min(currentPage * limit, totalItems);

    const [activeTab, setActiveTab] = useState('Quotations Requested');
    const [currentTitle, setCurrentTitle] = useState(activeTab);

    return (
        <>
            <div className={`${showtablecss ? 'custom-table-css' : 'another-table-css'} `}>
                {showSearchBar &&
                    <div className='searchBar mb-4'>
                        <div className={window.innerWidth < 900 ? 'col-md-12 my-2' : 'col-xl-12 d-flex justify-content-between align-items-center searchbar p-0'}>
                            <div className='d-flex align-items-center ms-3'>
                                <img src={Search} style={{ width: 18, objectFit: 'contain', marginRight: 16 }} />
                                <input value={searchText} onChange={handleSearchChange} type='text' placeholder='Search' className='searchbox' style={{ width: '650px' }} />
                            </div>
                            {showSearchBarButtons &&
                                <div className={window.innerWidth < 900 ? 'my-1' : 'd-flex align-items-center'}>
                                    <button className='whiteBtn'>Date Range
                                        <img src={DateRange} className='ms-3' style={{ width: 14, objectFit: 'contain' }} />
                                    </button>
                                    <button className='whiteBtn me-1'>Report
                                        <img src={Report} className='ms-3' style={{ width: 9, objectFit: 'contain' }} />
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                }
                <div style={{ overflowX: 'auto', padding: '0 0', width: window.innerWidth < 900 ? window.innerWidth - 70 : '' }}>
                    <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '2px 16px' }} className={window.innerWidth < 900 ? 'mt-4' : 'custom-table'} cellSpacing="6" cellPadding="10">
                        <thead>

                            <tr className='table-head'>
                                {headers.map((header, index) => (
                                    <th className='table-header' key={index}>{header}</th>
                                ))}
                                {showStatusColumn && <th>Status</th>}
                                {showActions &&
                                    <th className='table-header'>{actionsHeaderText || ''} </th>
                                }
                                {showActions2 &&
                                    <th className='table-header'>{actionsHeaderText2 || ''} </th>
                                }
                                {showTableImg && <th className='table-header'>{ImgHeaderText || ''}</th>}

                            </tr>
                        </thead>
                        <tbody className='table-body'>
                            {data?.length === 0 ? (
                                <tr>
                                    <td style={{ height: '15vh' }} colSpan={headers?.length + (showActions ? 1 : 0) + (showStatusColumn ? 1 : 0)} className='text-center'>
                                        No data found
                                    </td>
                                </tr>
                            ) : (data?.map((rowData, rowIndex) => (
                                <>
                                    <tr key={rowIndex} className='table-body-tr' style={{ position: 'relative' }}>
                                        {Object.entries(rowData)?.map(([key, value], colIndex) => {
                                            const classNames = {
                                                'Moderately High': 'table-green-class',
                                                'Very High': 'table-dark-green-class',
                                                'Moderate': 'table-yellow-class',
                                                'Moderately Low': 'table-light-red-class',
                                                'Low': 'table-red-class',
                                            };

                                            let className = '';
                                            if (headers[colIndex] === 'Risk Profile') {
                                                className = classNames[value];
                                            }

                                            if (headers[colIndex] === 'Returns') {
                                                className = 'green-text';
                                            }

                                            if (key === 'goalType') {
                                                return (
                                                    <td className='table-row' key={colIndex}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            {showBankIcon && showIcon && <p style={{ width: 32, marginRight: 8 }}>{value.goalType}</p>}

                                                            <div>
                                                                <div className='table-big-text'>{value.text}</div>
                                                                <div className='table-small-text'>{value.subtext}</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                );
                                            }

                                            if (key === 'goal_type') {
                                                return (
                                                    <td className='table-row' key={colIndex} style={{ display: 'none' }}>
                                                        <div>
                                                            <div className='table-big-text'>{value.text}</div>
                                                            <div className='table-small-text'>{value.subtext}</div>
                                                        </div>
                                                    </td>
                                                );
                                            }

                                            return (
                                                <td className='table-row' key={colIndex}>
                                                    <div className={className} style={{
                                                        display: expandedRow === rowIndex ? 'flex' : '',
                                                        alignItems: expandedRow === rowIndex ? 'start' : '',
                                                        height: window.innerWidth < 900 ? (expandedRow === rowIndex ? 250 : '') : (expandedRow === rowIndex ? 180 : '')
                                                    }}>
                                                        {value}
                                                    </div>
                                                </td>
                                            )
                                        })}
                                        {showActions && <td style={{ display: expandedRow === rowIndex ? 'flex' : '', alignItems: expandedRow === rowIndex ? 'start' : '', }}>
                                            <div className='d-flex align-items-center justify-content-end'>
                                                {showAddButton &&
                                                    <button className='blue-add-btn me-3' onClick={() => onAddButtonClick(rowData)}>Add Funds<img src={addIcon} style={{ marginLeft: 4, width: 24, objectFit: 'contain' }} /></button>
                                                }
                                                {showAddIcon && <button className='blue-add-btn me-3' onClick={() => onAddClick(rowData)}>Add Funds<img src={addIcon} style={{ marginLeft: 4, width: 24, objectFit: 'contain' }} /></button>}
                                                {showDeleteIcon &&
                                                    <img src={DeleteIcon} onClick={() => onDeleteClick(rowData)} style={{ width: 24, objectFit: 'contain', marginLeft: 20,cursor: 'pointer' }} />
                                                }
                                                {showEditButton && <button className='blue-add-btn me-3' onClick={() => onEditClick(rowData)}>Edit Funds<img src={addIcon} style={{ marginLeft: 4, width: 24, objectFit: 'contain' }} /></button>}
                                                {showView && <button className='blue-add-btn' onClick={() => onViewClick(rowData)}>View</button>}
                                            </div>
                                        </td>
                                        }
                                    </tr>
                                </>
                            )))}
                        </tbody>
                    </table>
                </div>
                {showPagination && (
                    <div className={` ${window.innerWidth < 900 ? 'custom-table-width-mobile' : ''}`}>
                        <div className='d-flex align-items-center justify-content-between'>
                            <div className='d-flex align-items-center justify-content-start'>
                                <Button style={{ background: 'none', boxShadow: 'none', border: '1px solid #6F6B7D', color: '#6F6B7D' }} onClick={handleClick} variant="contained">
                                    1 - {limit} <ArrowDropDownIcon style={{ padding: '0px', margin: '0px' }} />
                                </Button>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    {rowsPerPageOptions?.map((rows) => (
                                        <MenuItem key={rows} onClick={() => onRowsPerPageChange(rows)} style={{ fontSize: 16 }}>
                                            1 - {rows}
                                        </MenuItem>
                                    ))}
                                </Menu>
                                <p className='table-page-num mx-3'>Showing {startIndex} - {endIndex} of {totalItems}</p>
                            </div>
                            <div className='d-flex align-items-center justify-content-start'>
                                <img
                                    src={PageNavLeft}
                                    style={{
                                        marginRight: 5,
                                        width: '7px',
                                        cursor: 'pointer',
                                        pointerEvents: currentPage === 1 ? 'none' : 'auto',
                                        opacity: currentPage === 1 ? 0.4 : 1,
                                    }}
                                    onClick={() => handlePageChange(currentPage - 1)}
                                />

                                {/* Render pagination only if there are more than one pages */}
                                {totalPages > 1 && (
                                    <>
                                        {/* First Page */}
                                        <div
                                            className={`${currentPage === 1 ? 'table-page-num-text-active' : 'table-page-num-text'}`}
                                            onClick={() => handlePageChange(1)}
                                        >
                                            1
                                        </div>

                                        {/* Ellipsis before the current page group */}
                                        {currentPage > 3 && (
                                            <div className="table-page-num-text">...</div>
                                        )}

                                        {[...Array(totalPages).keys()]
                                            .filter(page =>
                                                page + 1 > 1 && // Exclude first page
                                                page + 1 < totalPages && // Exclude last page
                                                page + 1 >= currentPage - 1 && page + 1 <= currentPage + 1
                                            )
                                            .map((page) => (
                                                <div
                                                    key={page + 1}
                                                    className={`${currentPage === page + 1 ? 'table-page-num-text-active' : 'table-page-num-text'}`}
                                                    onClick={() => handlePageChange(page + 1)}
                                                >
                                                    {page + 1}
                                                </div>
                                            ))}

                                        {currentPage < totalPages - 2 && (
                                            <div className="table-page-num-text">...</div>
                                        )}

                                        {totalPages > 1 && (
                                            <div
                                                className={`${currentPage === totalPages ? 'table-page-num-text-active' : 'table-page-num-text'}`}
                                                onClick={() => handlePageChange(totalPages)}
                                            >
                                                {totalPages}
                                            </div>
                                        )}
                                    </>
                                )}

                                <img
                                    src={PageNavRight}
                                    style={{
                                        marginLeft: 5,
                                        width: '7px',
                                        cursor: 'pointer',
                                        pointerEvents: currentPage === totalPages ? 'none' : 'auto',
                                        opacity: currentPage === totalPages ? 0.4 : 1,
                                    }}
                                    onClick={() => handlePageChange(currentPage + 1)}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div >
            {/* {showPagination &&
                <div className='d-flex justify-content-end align-items-center my-4'>
                    <button className='paginationBtn-gray'>Previous</button>
                    <div className='paginationBtn-blue'>1</div>
                    <div className='paginationBtn-gray'>2</div>
                    <div className='paginationBtn-gray'>3</div>
                    <div className='paginationBtn-gray'>4</div>
                    <div className='paginationBtn-gray'>5</div>
                    <button className='paginationBtn-gray'>Next</button>
            </div>
            } */}
        </>
    )
}

export default CustomTable;