import React, { useEffect, useState } from 'react';
import TableImg from "../../assets/images/TableImage.png";
import CustomTable from '../../components/customTable/CustomTable';
import Modal from 'react-bootstrap/Modal';
import '../fundsBasket/TabContent.scss';
import Search from "../../assets/images/searchIcon.png";
import NipponIcon from "../../assets/images/NipponIcon.png";
import { addFunds, getGoals, getMfDeatils, removeMfDetails, viewGoalDetail } from './fundBasketSlice';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/loader/Loader';
import { toast } from 'react-toastify';

function TabContent({ data, activeTab, selectedItem, goalsList }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentModalPage, setCurrentModalPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [limit1, setLimit1] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [goalId, setGoalId] = useState('');
  const [modalSearchText, setModalSearchText] = useState('');
  const [goalName, setGoalName] = useState('');
  
  const dispatch = useDispatch();
  const { isLoading, fundList, goalDetail } = useSelector(state => state.fundBasketSlice);
  const tableHeaders = ['Id', 'Goal Type', 'Number of Funds'];

  useEffect(() => {
    let queryParam = `?goal_type=${activeTab}&pageSize=${limit}&page=${currentPage}&risk_profile=${selectedItem}`
    if (searchText) {
      queryParam += `&search=${searchText}`;
    }
    dispatch(getGoals(queryParam)).unwrap()
      .catch(err => {
        console.error('Failed to fetch data:', err);
      });
  }, [activeTab, selectedItem, searchText, currentPage, limit]);

  useEffect(() => {
    if (modalSearchText) {
      let queryParam = `?search=${modalSearchText}&pageSize=${limit1}&page=${currentModalPage}`;
      dispatch(getMfDeatils(queryParam)).unwrap()
        .catch(err => {
          console.error('Failed to fetch data:', err);
        });
    }
  }, [modalSearchText, currentModalPage, limit1]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal1 = () => {
    setIsModalOpen1(true);
  }; 

  const closeModal1 = () => {
    setIsModalOpen1(false);
  };

  const handleAddModal = (rowData) => {
    openModal()
    setGoalId(rowData?.goalId)
    setModalSearchText('')
    dispatch(getMfDeatils(`?goal_type=${rowData.goal_type}`)).unwrap()
      .catch(err => {
        console.error('Failed to fetch data:', err);
      });
  }

  const handleAddClick = (rowData) => {
    dispatch(addFunds(`?id=${rowData.id}&goalid=${goalId}&risk_profile=${selectedItem}`)).unwrap()
      .then((res) => {
        dispatch(getGoals(`?goal_type=${activeTab}&risk_profile=${selectedItem}`)).unwrap()
        closeModal()
        setGoalId('')
        toast.success(res?.message)
      })
      .catch(err => {
        console.error('Failed to fetch data:', err);
        toast.error(err?.message)
      });
  }

  const userData = goalsList?.rows ? goalsList?.rows?.map((item) => ({
    goalId: item?.pre_defined_goals_id,
    goalType: {
      text: item?.goal_name
    },
    goal_type: item.goal_type || 'null',
    numberOfFunds: item.total_count
  })) : [];

  const modalHeaders = ['Id', 'Mutual Fund', 'ISIN'];
  const modalViewHeaders = ['Id', 'Mutual Fund', 'ISIN'];

  const modalData = data?.rows && data?.rows.length
    ? data?.rows?.map((item, index) => ({
      id: item?.id,
      goalType: {
        showBankIcon: true,
        // icon1: NipponIcon,
        imagewidth: 20,
        text: item?.sch_name || 'null',
        subtext: item.Category || 'null',
      },
      isin: item.isin
    }))
    : [];

  const goalsData = goalDetail && goalDetail?.length
    ? goalDetail?.map((item, index) => ({
      id: item?.id,
      goal_type: item?.fundbasket_id,
      goalType: {
        showBankIcon: true,
        // icon1: NipponIcon,
        imagewidth: 20,
        text: item?.sch_name || 'null',
        subtext: item.Category || 'null',
      },
      isin: item?.isin,
    }))
    : [];

  const handleViewClick = (rowData) => {
    openModal1()
    setGoalName(rowData?.goalType?.text)
    if (rowData?.goalId) {
      let queryParam = `?pre_defined_goals_id=${rowData?.goalId}&risk_profile=${selectedItem}`;
      dispatch(viewGoalDetail(queryParam)).unwrap()
        .then((res) => {
          // toast.success(res?.message)
        })
        .catch(err => {
          toast.error(err?.message)
          console.error('Failed to fetch data:', err);
        });
    }
  };

  const handleDeleteClick = (rowData) => {
    let queryParam = `?id=${rowData?.goal_type}`;
    dispatch(removeMfDetails(queryParam)).unwrap()
      .then((res) => {
        dispatch(getGoals(`?goal_type=${activeTab}&risk_profile=${selectedItem}`)).unwrap()
        toast.success(res?.message)
        closeModal1()
      })
      .catch(err => {
        toast.error(err?.message)
        console.error('Failed to fetch data:', err);
      });
  }

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const handleModalPageChange = page => {
    setCurrentModalPage(page);
  };

  const handleSearchChange = (newSearchText) => {
    setSearchText(newSearchText);
  };

  const handleModalSearchChange = (newModalSearchText) => {
    setModalSearchText(newModalSearchText);
    setCurrentModalPage(1)
  };

  return (
    <div>
      {!searchText && !modalSearchText && isLoading && <Loader />}
      <CustomTable
        data={userData}
        headers={tableHeaders}
        showSearchBar='true'
        actionsHeaderText=''
        showActions
        showAddIcon
        onAddClick={(rowData) => handleAddModal(rowData)}
        showIcon={true}
        showtablecss={true}
        showPagination={true}
        totalPages={goalsList?.totalPages}
        onPageChange={handlePageChange}
        currentPage={currentPage}
        getLimit={(row) => setLimit(row)}
        totalItems={goalsList?.count}
        onSearchChange={handleSearchChange}
        showView={true}
        onViewClick={handleViewClick}
      />
      <Modal size='lg' show={isModalOpen} className='modal-css' centered>
        <Modal.Header style={{ borderBottom: 'none' }}>
          <div className='bluebox cursor-pointer' onClick={closeModal}>
            <p className='backtext'>Back</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          {/* <div className='searchbar pt-2'>
            <div className='d-flex align-items-center ms-3'>
              <img src={Search} style={{ width: 18, objectFit: 'contain', marginRight: 8 }} alt="Search" />
              <input type='text' placeholder='Search by lorem ipsum' className='w-100 searchbox' />
            </div>
          </div> */}
          <CustomTable
            data={modalSearchText ? modalData : []}
            headers={modalHeaders}
            actionsHeaderText='Actions '
            showActions
            showBankIcon={true}
            showtablecss={true}
            showPagination={true}
            totalPages={modalSearchText ? fundList?.totalPages : 0}
            onPageChange={handleModalPageChange}
            currentPage={currentModalPage}
            getLimit={(row) => setLimit1(row)}
            totalItems={modalSearchText ? fundList?.count : 0}
            onSearchChange={handleModalSearchChange}
            showAddButton={true}
            onAddButtonClick={(rowData) => { handleAddClick(rowData) }}
            showSearchBar={true}
          />
        </Modal.Body>
      </Modal>

      <Modal size='lg' show={isModalOpen1} className='modal-css' centered>
        <Modal.Header style={{ borderBottom: 'none' }}>
          <div className='bluebox cursor-pointer' onClick={closeModal1}>
            <p className='backtext'>Back</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <h4>Goal Name : {goalName}</h4>
          <CustomTable
            data={goalsData}
            headers={modalViewHeaders}
            showDeleteIcon={true}
            showBankIcon={true}
            showtablecss={true}
            showActions={true}
            onDeleteClick={handleDeleteClick}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default TabContent;
