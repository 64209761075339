import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../../utils/apiUrls";
import api from "../../utils/api";

const initialState = {
    fundList: [],
    goalsList: [],
    goalDetail: [],
    isLoading: false,
    error: null
};



export const getMfDeatils = createAsyncThunk("admin/getMfDeatils", async (queryParam) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getMfDeatils + queryParam,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        console.log('error::::', error.response)
        throw error.response.data.message;
    }
});

export const getGoals = createAsyncThunk("admin/getGoals", async (queryParam) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.getGoals + queryParam,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        console.log('error::::', error.response)
        throw error.response.data.message;
    }
});

export const addFunds = createAsyncThunk("admin/AddMfDetails", async (queryParam) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.addFunds + queryParam,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        console.log('error::::', error.response)
        throw error.response.data.message;
    }
});

export const viewGoalDetail = createAsyncThunk("admin/viewGoalDetail", async (queryParam) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.viewGoalDetail + queryParam,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        console.log('error::::', error.response)
        throw error.response.data.message;
    }
});

export const removeMfDetails = createAsyncThunk("admin/removeMfDetails", async (queryParam) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.removeMfDetails + queryParam,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        console.log('error::::', error.response)
        throw error.response.data.message;
    }
});

const fundBasketSlice = createSlice({
    name: "fundSlice",
    initialState,
    reducers: {
        // setUserName: (state, action) => {
        //     state.userName = action.payload
        // },
    },
    extraReducers: (builder) => {
        const handleLoading = (state, action) => {
            state.isLoading = action.meta.requestStatus === 'pending';
        };

        builder
            .addCase(getMfDeatils.fulfilled, (state, action) => {
                state.fundList = action.payload?.data;
            })
            .addCase(getGoals.fulfilled, (state, action) => {
                state.goalsList = action.payload?.data;
            })
            .addCase(viewGoalDetail.fulfilled, (state, action) => {
                state.goalDetail = action.payload?.data;
            })
            .addMatcher(
                (action) =>
                    action.type === getMfDeatils.pending.type ||
                    action.type === getMfDeatils.fulfilled.type ||
                    action.type === getMfDeatils.rejected.type ||
                    action.type === getGoals.pending.type ||
                    action.type === getGoals.fulfilled.type ||
                    action.type === getGoals.rejected.type ||
                    action.type === addFunds.pending.type ||
                    action.type === addFunds.fulfilled.type ||
                    action.type === addFunds.rejected.type ||
                    action.type === viewGoalDetail.pending.type ||
                    action.type === viewGoalDetail.fulfilled.type ||
                    action.type === viewGoalDetail.rejected.type ,
                handleLoading
            );
    }

});

// export const { setUserName } = fundBasketSlice.actions;
export default fundBasketSlice.reducer;