import React from 'react'
import StarIcon from "../../assets/images/StarIcon.png"
import '../investbox/InvestBox.scss'
import GreenTick from "../../assets/images/GreenTick.png"

function InvestBox({imagesource1,boxhead1,boxhead2,showImage,imagesource2}) {
    return (
        <>
            <div className=' investbox-css'>
                <div className='mainbox3'>
                    <div className='d-flex align-items-center justify-content-between '>
                        <div className='d-flex' >
                            <img src={imagesource1} className='staricon' />
                            <p className='boxheading'>{boxhead1}</p>
                        </div>
                        <div className='d-flex'>
                            <p className='boxheading text1 me-2'>{boxhead2}</p>
                            {showImage && <img src={imagesource2} className='staricon' />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InvestBox