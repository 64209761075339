import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Tab } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import SplitButton from 'react-bootstrap/SplitButton';
import '../fundsBasket/FundsBasket.scss';
import TabContent from './TabContent';
import { useDispatch, useSelector } from 'react-redux';
import { getGoals, getMfDeatils } from './fundBasketSlice'; // Corrected function name
import Loader from '../../components/loader/Loader';

function FundsBasket() {
  const { isLoading, fundList, goalsList, error } = useSelector(state => state.fundBasketSlice);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("SMALL");
  const [selectedItem, setSelectedItem] = useState('Very High');

  // useEffect(() => {
  //   if (activeTab) {
  //     dispatch(getGoals(`?goal_type=${activeTab}`))
  //   }
  // }, [activeTab]);

  // useEffect(() => {
  //   if (activeTab) {
  //     fetchData(activeTab, selectedItem);
  //   }
  // }, [activeTab, selectedItem]);

  // const fetchData = (goalId, riskProfile) => {
  //   dispatch(getMfDeatils(`?goal_type=${goalId}&risk_profile=${riskProfile}`))
  //     .catch(err => {
  //       console.error('Failed to fetch data:', err);
  //     });
  // };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const handleSelect = (eventKey) => {
    setSelectedItem(eventKey);
  };

  return (
    <div className='fundsBasket main-Page-css'>
      {isLoading && <Loader />}
      {error && <div className="error-message">Failed to load data</div>}

      <div className='main-page-title'>Risk Profile</div>
      <SplitButton
        align={{ lg: 'start' }}
        title={selectedItem}
        id="dropdown-menu-align-responsive-2"
        onSelect={handleSelect}
      >
        <Dropdown.Item eventKey="Very High">Very High</Dropdown.Item>
        <Dropdown.Item eventKey="Moderately High">Moderately High</Dropdown.Item>
        <Dropdown.Item eventKey="Moderate">Moderate</Dropdown.Item>
        <Dropdown.Item eventKey="Moderately Low">Moderately Low</Dropdown.Item>
        <Dropdown.Item eventKey="Low">Low</Dropdown.Item>
      </SplitButton>

      <div className='riskprofile-css mt-4'>
        <Tab.Container activeKey={activeTab} onSelect={handleTabChange}>
          <div className={window.innerWidth < 900 ? '' : 'd-flex justify-content-between align-items-center'}>
            <Nav variant="underline">
              <Nav.Item>
                <Nav.Link eventKey="SMALL" className={window.innerWidth < 700 ? 'm-0' : ''}>Short-Term</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="MID" className={window.innerWidth < 700 ? 'm-0' : ''}>Mid-Term</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="LARGE" className={window.innerWidth < 700 ? 'm-0' : ''}>Long-Term</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          <Tab.Content>
            <Tab.Pane eventKey={activeTab}>
              <TabContent data={fundList} activeTab={activeTab} selectedItem={selectedItem} goalsList={goalsList} />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
}

export default FundsBasket;
