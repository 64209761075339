import React from 'react'
import BankSecure from "../../assets/images/BankSecure.png"
import "../investmentCard/InvestmentCard.scss"

function InvestmentCard({imagesource1,boxhead1,boxtitle1,boxsubtitle1,boxtitle2,boxsubtitle2}) {
    return (
        <>
            <div className='investmentcard-css'>
                <div className='mainbox1'>
                    <div className='d-flex align-items-center box1 pb-1 '>
                        <img src={imagesource1} className='bankicon' />
                        <p className='boxheading'>{boxhead1}</p>
                    </div>
                    <div className='box2'>
                        <div className='box3'>
                            <p className='boxtitle'>{boxtitle1}</p>
                            <p className='boxsubheading'>{boxsubtitle1}</p>
                        </div>
                        <div className='box3'>
                            <p className='boxtitle'>{boxtitle2}</p>
                            <p className='boxsubheading m-0'>{boxsubtitle2}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InvestmentCard