import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ENDPOINTS, METHOD_TYPE } from "../utils/apiUrls";
import api from "../utils/api";


const initialState = {
    // userName: '',
    userList: [],
    userInvestments: [],
    oneViewData: [],
    isLoading: false,
    error: null
};

export const adminLogin = createAsyncThunk("admin/adminLogin", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.adminLogin,
            data: requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        console.log('error::::', error.response)
        throw error.response.data.message;
    }
});

export const verifyAdminLoginOtp = createAsyncThunk("admin/verifyAdminLoginOtp", async (requestData) => {
    try {
        let data = {
            method: METHOD_TYPE.post,
            url: API_ENDPOINTS.verifyAdminLoginOtp,
            data: requestData
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        console.log('error::::', error.response)
        throw error.response.data.message;
    }
});

export const fetchUserDetails = createAsyncThunk("admin/fetchUserDetails", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchUserDetails + queryParams,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        console.log('error::::', error.response)
        throw error.response.data.message;
    }
});

export const fetchUserInvestments = createAsyncThunk("admin/fetchUserInvestments", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchUserInvestments + queryParams,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        console.log('error::::', error.response)
        throw error.response.data.message;
    }
});

export const fetchOneViewData = createAsyncThunk("admin/fetchOneViewData", async (queryParams) => {
    try {
        let data = {
            method: METHOD_TYPE.get,
            url: API_ENDPOINTS.fetchOneViewData + queryParams,
        };
        const response = await api(data);
        return response.data;

    } catch (error) {
        console.log('error::::', error.response)
        throw error.response.data.message;
    }
});

const adminSlice = createSlice({
    name: "slice",
    initialState,
    reducers: {
        // setUserName: (state, action) => {
        //     state.userName = action.payload
        // },
    },
    extraReducers: (builder) => {
        const handleLoading = (state, action) => {
            state.isLoading = action.meta.requestStatus === 'pending';
        };

        builder
            .addCase(fetchUserDetails.fulfilled, (state, action) => {
                state.userList = action.payload?.data;
            })
            .addCase(fetchUserInvestments.fulfilled, (state, action) => {
                state.userInvestments = action.payload?.data;
            })
            .addCase(fetchOneViewData.fulfilled, (state, action) => {
                state.oneViewData = action.payload;
            })
            .addMatcher(
                (action) =>
                    action.type === adminLogin.pending.type ||
                    action.type === adminLogin.fulfilled.type ||
                    action.type === adminLogin.rejected.type ||
                    action.type === verifyAdminLoginOtp.pending.type ||
                    action.type === verifyAdminLoginOtp.fulfilled.type ||
                    action.type === verifyAdminLoginOtp.rejected.type ||
                    action.type === fetchUserDetails.pending.type ||
                    action.type === fetchUserDetails.fulfilled.type ||
                    action.type === fetchUserDetails.rejected.type ||
                    action.type === fetchUserInvestments.pending.type ||
                    action.type === fetchUserInvestments.fulfilled.type ||
                    action.type === fetchUserInvestments.rejected.type || 
                    action.type === fetchOneViewData.pending.type ||
                    action.type === fetchOneViewData.fulfilled.type ||
                    action.type === fetchOneViewData.rejected.type ,
                handleLoading
            );
    }

});

// export const { setUserName } = adminSlice.actions;
export default adminSlice.reducer;