import React, { useEffect, useState } from 'react'
import CustomTable from '../../components/customTable/CustomTable';
import UserBox from '../../components/userBox/UserBox';
import { useNavigate } from 'react-router-dom';
import UsersIcon from "../../assets/images/UsersIcon.png"
import { useDispatch, useSelector } from 'react-redux';
import { fetchOneViewData, fetchUserDetails } from '../slice';
import { toast } from 'react-toastify';
import moment from 'moment';
import Loader from '../../components/loader/Loader';

function Users() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, userList, oneViewData } = useSelector(state => state.adminSlice);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersData, setUsersData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    let queryParam = `?page=${currentPage}&limit=${limit}`;
    if (searchText) {
      queryParam += `&search=${searchText}`;
    }
    dispatch(fetchUserDetails(queryParam))
  }, [currentPage, limit, searchText]);

  useEffect(() => {
    if (userList) {
      fetchUserData();
    }
  }, [userList, currentPage, limit, searchText]);

  const fetchUserData = () => {
    const newData = userList?.data?.rows?.map(data => {
      return {
        id: data?.userId,
        firstname: `${data?.firstName ? data?.firstName : ''} ${data?.lastName ? data?.lastName : ''}`,
        email: data?.email,
        mobile: data?.mobileNumber,
        date: moment(data.createdAt).format('DD-MM-YYYY')
      };
    });
    setUsersData(newData);
  };

  const handleSearchChange = (newSearchText) => {
    setSearchText(newSearchText);
  };

  useEffect(() => {
    const queryParams = ''
    dispatch(fetchOneViewData(queryParams))
  }, [dispatch]);


  const userdata = [
    {
      boxtitle: 'Total Number Of Users',
      num: userList?.totalCount,
      imagesource: UsersIcon
    },
    {
      boxtitle: 'Total One-View Users',
      num: oneViewData?.data?.count,
      imagesource: UsersIcon
    },
    {
      boxtitle: 'Total Investments ',
      num: '₹10000000',
      imagesource: UsersIcon
    }
  ]

  const tableHeaders = ['Id', 'Full Name', 'Email', 'Phone', 'Date'];

  const userData = [
    {
      id: '1',
      fullName: 'John Doe',
      email: 'John@gmail.com',
      phone: '0000000000',
      date: '10/10/2023',
      document: 'Attached'
    },
    {
      id: '1',
      fullName: 'John Doe',
      email: 'John@gmail.com',
      phone: '0000000000',
      date: '10/10/2023',
      document: 'Attached'
    },
    {
      id: '1',
      fullName: 'John Doe',
      email: 'John@gmail.com',
      phone: '0000000000',
      date: '10/10/2023',
      document: 'Attached'
    },
    {
      id: '1',
      fullName: 'John Doe',
      email: 'John@gmail.com',
      phone: '0000000000',
      date: '10/10/2023',
      document: 'Attached'
    },
    {
      id: '1',
      fullName: 'John Doe',
      email: 'John@gmail.com',
      phone: '0000000000',
      date: '10/10/2023',
      document: 'Attached'
    },
  ]

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const handleViewClick = (rowData) => {
    navigate('/userlist', { state: { rowData } });
  }
  return (
    <>
      {!searchText && isLoading && <Loader />}
      <div className='dashboard main-Page-css'>
        <div className='main-page-title'>User list</div>
        <div className='row p-0'>

          {userdata?.map((data, index) => (
            <div className='col-4'>
              <UserBox key={index} {...data} />
            </div>
          ))}

        </div>
        <div style={{ marginTop: 32 }}>
          <CustomTable
            data={usersData}
            headers={tableHeaders}
            showSearchBar='true'
            actionsHeaderText='Action'
            showActions
            showView
            showPagination={true}
            showtablecss={true}
            onViewClick={(rowData) => handleViewClick(rowData)}
            totalPages={userList?.data?.totalPages}
            onPageChange={handlePageChange}
            currentPage={currentPage}
            getLimit={(row) => setLimit(row)}
            totalItems={userList?.data?.count}
            onSearchChange={handleSearchChange}
          />
        </div>
      </div>
    </>
  )
}

export default Users