import Dashboard from "../pages/dashboard/Dashboard";
import UserList from "../pages/dashboard/UserList";
import FundsBasket from "../pages/fundsBasket/FundsBasket";
import Login from "../pages/login/Login";
import Users from "../pages/users/Users";
import PrivateRoute from "./privateRoute";

export const routes = [
    {
        path: "/",
        exact: true,
        element: <Login />,
    },
    {
        path: "/login",
        exact: true,
        element: <Login />,
    },
    {
        path: "/dashboard",
        exact: true,
        element: <PrivateRoute component={Dashboard} />,
    },
    {
        path: "/funds-basket",
        exact: true,
        element: <PrivateRoute component={FundsBasket} />,
    },
    {
        path: "/users",
        exact: true,
        element: <PrivateRoute component={Users} />,
    },
    {
        path: "/userlist",
        exact: true,
        element: <PrivateRoute component={UserList} />,
    },
]