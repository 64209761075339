export const METHOD_TYPE = {
    get: "get",
    post: "post",
    put: "put",
    delete: "delete",
};

export const API_ENDPOINTS = {
    adminLogin: 'admin/adminLogin',
    verifyAdminLoginOtp: 'admin/verifyAdminLoginOtp',
    fetchUserDetails: 'admin/fetchUserDetails',
    fetchUserInvestments: 'admin/fetchUserInvestments',
    fetchOneViewData : 'admin/fetchOneViewData',
    getMfDeatils:'admin/getMfDetails',
    getGoals:'admin/getGoals',
    addFunds: 'admin/AddMfDetails',
    viewGoalDetail: 'admin/viewGoalDetail',
    removeMfDetails: 'admin/removeMfDetails'
}