import React from 'react'
import UserBox from '../../components/userBox/UserBox'
import InvestmentCard from '../../components/investmentCard/InvestmentCard'
import InvestBox from '../../components/investbox/InvestBox'
import StarIcon from "../../assets/images/StarIcon.png"
import GreenTick from "../../assets/images/GreenTick.png"
import UsersIcon from "../../assets/images/UsersIcon.png"
import CustomTable from '../../components/customTable/CustomTable';
import { useNavigate } from 'react-router-dom'

function Dashboard() {
  // let navigate = useNavigate();

  // const userdata = [
  //   {
  //     boxtitle: 'Total Number Of Users',
  //     num: '100',
  //     imagesource: UsersIcon
  //   },
  //   {
  //     boxtitle: 'Total One-View Users',
  //     num: '100',
  //     imagesource: UsersIcon
  //   },
  //   {
  //     boxtitle: 'Total Investments ',
  //     num: '₹10000000',
  //     imagesource: UsersIcon
  //   }
  // ]

  // const tableHeaders = ['Id', 'Full Name', 'Email', 'Phone', 'Date', 'Document'];

  // const userData = [
  //   {
  //     id: '1',
  //     fullName: 'John Doe',
  //     email: 'John@gmail.com',
  //     phone: '0000000000',
  //     date: '10/10/2023',
  //     document: 'Attached'
  //   },
  //   {
  //     id: '1',
  //     fullName: 'John Doe',
  //     email: 'John@gmail.com',
  //     phone: '0000000000',
  //     date: '10/10/2023',
  //     document: 'Attached'
  //   },
  //   {
  //     id: '1',
  //     fullName: 'John Doe',
  //     email: 'John@gmail.com',
  //     phone: '0000000000',
  //     date: '10/10/2023',
  //     document: 'Attached'
  //   },
  //   {
  //     id: '1',
  //     fullName: 'John Doe',
  //     email: 'John@gmail.com',
  //     phone: '0000000000',
  //     date: '10/10/2023',
  //     document: 'Attached'
  //   },
  //   {
  //     id: '1',
  //     fullName: 'John Doe',
  //     email: 'John@gmail.com',
  //     phone: '0000000000',
  //     date: '10/10/2023',
  //     document: 'Attached'
  //   },
  // ]

  // const handleViewClick = () => {

  //   let path = `/userlist`;
  //   navigate(path);
  // }

  return (
    <>
      <p>Dashboard</p>
    </>
  )
}

export default Dashboard