import React, { useState } from 'react'
import LoginImg from '../../assets/images/login-img.png'
import '../login/Login.scss'
import OtpInput from 'react-otp-input';
import Logo from '../../assets/images/DigiRm.png'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { adminLogin, verifyAdminLoginOtp } from '../slice';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/loader/Loader';

function Login() {
  const dispatch = useDispatch()
  const [showCode, setShowCode] = useState(false);
  const [otp, setOtp] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const { isLoading } = useSelector(state => state.adminSlice);

  const navigate = useNavigate();
  const routeChange = () => {
    let path = "/users";
    navigate(path);
  }

  const handleGetOtp = () => {
    if (!emailAddress) {
      toast.error("Please enter your email")
      return;
    }
    const data = {
      email: emailAddress
    }
    dispatch(adminLogin(data))
      .unwrap()
      .then((res) => {
        setShowCode(true)
        toast.success(res?.message)

      })
      .catch((error) => {
        toast.error(error?.message)
      })
  }

  const handleLogin = async () => {
    const regex = /^[0-9]{0,6}$/; // Regular expression to match 6 digits
    if (!regex.test(otp) || otp?.length < 6) {
      toast.error("Please enter valid otp")
      return;
    }
    const requestData = {
      email: emailAddress,
      otp
    }
    await dispatch(verifyAdminLoginOtp(requestData))
      .unwrap()
      .then(async (res) => {
        localStorage.setItem('token', res.data.token)
        let path = `/users`;
        navigate(path);
        toast.success("Logged In Successfully")
      })
      .catch((error) => {
        toast.error(error?.message)
      })
  }

  return (
    <section className='login' style={{ backgroundColor: '#FFFFFF' }}>
      {isLoading && <Loader />}
      <div className="d-flex justify-content-center align-items-center">
        <div className='col-lg-7 col-md-8 h-100 login-page' >
          <div className="d-flex justify-content-center align-items-center imagecontainer" style={{ margin: 32, backgroundColor: '#f8f7fa', height: window.innerHeight - 64, borderRadius: 20 }}>
            {showCode &&
              <img src={LoginImg} className='loginImage' style={{ objectFit: 'contain', width: '100%', height: window.innerHeight / 2 }} />
            }
            {!showCode &&
              <img src={LoginImg} className='loginImage' style={{ objectFit: 'contain', width: '100%', height: window.innerHeight / 2 }} />
            }
          </div>
        </div>
        <div className='col-md-7 col-lg-5 login-form'>
          <div>
            <img src={Logo} className='logo-img mb-3' />
            {showCode && <p className='welcomeText'>Two-Step Verification 💬</p>}
            {!showCode && <p className='welcomeText m-0'>Welcome to DigiRm! 👋</p>}

            {showCode &&
              <>
                <p className='verifyText'>We sent a verification code to your mobile. Enter the code from the mobile in the field below.</p><p className='mt-2 text-lightgray'>{emailAddress}</p>
              </>
            }
            {!showCode && <p className='verifyText'>Please sign in to your account and start the adventure </p>}

            {showCode &&
              <div className="form-outline ">
                <label className="form-label my-2">Type your 6 digit security code</label>
                <div className='d-flex justify-content-around'>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderInput={(props) => <input {...props} style={{ width: 60 }} className='verificationBox text-center mx-2' />}
                  />
                </div>
              </div>
            }
            {!showCode &&
              <div className="form-outline">
                <label className="form-label mt-4">Email</label>
                <input
                  type="text"
                  id="form3Example3"
                  className="form-control"
                  placeholder="Enter your email"
                  onChange={(event) => {
                    setEmailAddress(event.target.value);
                  }}
                />
              </div>
            }

            {showCode &&
              <div className="text-center align-items-center mt-3">
                <button type="button" className='blue-primary-btn w-100' onClick={handleLogin}>Continue</button>
              </div>
            }

            {!showCode &&
              <div className="text-center align-items-center mt-3">
                <button type="button" className='blue-primary-btn w-100' onClick={handleGetOtp}>Log in</button>
              </div>
            }
            {showCode && <p className='resendText text-center'>Didn't get the code? <a href=''>Resend</a></p>}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Login