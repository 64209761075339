import React from 'react'
import '../userBox/UserBox.scss'
import UsersIcon from "../../assets/images/UsersIcon.png"

function UserBox({boxtitle,num,imagesource}) {
  return (
    <>
      <div className='cardbox-css'>
        <div className='mainbox '>
          <div>
            <p className='box-title'>{boxtitle}</p>
            <p className='box-subheading'>{num}</p>
          </div>
          <img src={imagesource} className='usericon' />
        </div>
      </div>
    </>
  )
}

export default UserBox