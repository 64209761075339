import React, { useState } from 'react'
import { AnimatePresence, motion } from "framer-motion";
import { FaArrowRight, FaBars } from 'react-icons/fa';
import Dashboard from '../../assets/images/dashboard-icon.png';
import DashboardBlue from '../../assets/images/dashboard-black-icon.png.png';
import Users from '../../assets/images/users-white-icon.png';
import SideBarMenu from '../../assets/images/sideBarMenu.png';
import UsersBlack from '../../assets/images/users-black-icon.png';
import FundsBasket from '../../assets/images/fundsBasket-white-Icon.png';
import FundsBasketBlack from '../../assets/images/fundsBasket-black-Icon.png';
import Logo from '../../assets/images/DigiRmSideBarLogo.png';
import Logout from '../../assets/images/logout.png';
import { useLocation, Link, useNavigate } from "react-router-dom";
import '../sidebar/Sidebar.scss'

function Sidebar() {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    let navigate = useNavigate();
    const toggle = () => setIsOpen(!isOpen);
    const sidebarVariants = {
        true: {
            left: '0'
        },
        false: {
            left: '-100%'
        }
    }
    const [expanded, setExpaned] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isHovered1, setIsHovered1] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const [isHovered3, setIsHovered3] = useState(false);

    const handleLogout = () => {
        localStorage.clear();
        navigate('/login');
    }

    return (
        <>
            <div className='col-lg-3 col-md-3 col-sm-12 vh-100 h-100 sidebar-scss' style={{
                width: isOpen ? '5%' : '20%',
                transition: 'width 0.1s ease',
            }}>
                <>
                    <div className="bars" style={expanded ? { left: '85%', backgroundColor: 'transparent' } : { left: '2%', backgroundColor: '#fff' }} onClick={() => setExpaned(!expanded)}>
                        {expanded ?
                            <FaBars color='#1570EF' size={22} /> :
                            <FaBars color='#1570EF' size={22} />}
                    </div>
                    <AnimatePresence>
                        <motion.div className="sidebar-sticky"
                            variants={sidebarVariants}
                            animate={window.innerWidth <= 992 ? `${expanded}` : ''}
                            transition={{ duration: 0.3, ease: "easeInOut" }}
                        >
                            <div className='d-flex flex-column justify-content-between vh-100'>
                                <div className='mb-3'>
                                    <div className='d-flex align-items-normal' style={{ justifyContent: isOpen ? 'center' : 'space-between' }}>
                                        <img style={{ display: isOpen ? "none" : "block", width: '95.59px' }} src={Logo} className='Leftbar-logo' />
                                        <div className="bars-desktop" style={{ cursor: 'pointer', marginLeft: isOpen ? 25 : 25, marginTop: isOpen ? 20 : 20, marginBottom: isOpen ? 25 : 0, marginRight: 25 }} onClick={toggle}>
                                            {isOpen ? <FaArrowRight color='#00308F' size={22} /> :
                                                <img src={SideBarMenu} width={22} style={{ objectFit: 'contain' }} />
                                            }
                                        </div>
                                    </div>
                                    <div className='menu-links' style={{ paddingLeft: isOpen ? 0 : 10, paddingRight: isOpen ? 0 : 10 }}>
                                        <ul ul className='nav flex-column'>
                                            {/* <li
                                                onClick={() => setExpaned(!expanded)}
                                                onMouseEnter={() => setIsHovered(true)}
                                                onMouseLeave={() => setIsHovered(false)}
                                                className="nav-item"
                                            >
                                                <Link
                                                    style={{ justifyContent: isOpen ? 'center' : 'normal' }}
                                                    className={
                                                        location.pathname === '/dashboard' 
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    to="/dashboard"
                                                >
                                                    <img
                                                        src={isHovered || location.pathname === '/dashboard' ? Dashboard : DashboardBlue}
                                                        className='siderbar-icons'
                                                        alt="Dashboard Icon"
                                                    />
                                                    <p
                                                        style={{
                                                            display: isOpen ? 'none' : 'block',
                                                            color: (isHovered || location.pathname === '/dashboard') ? '#ffffff' : '#6F6B7D',
                                                        }}
                                                        className='navitems mb-0 sidetext'
                                                    >
                                                        Dashboard
                                                    </p>
                                                </Link>
                                            </li> */}
                                            <li
                                                onClick={() => setExpaned(!expanded)}
                                                onMouseEnter={() => setIsHovered1(true)}
                                                onMouseLeave={() => setIsHovered1(false)}
                                                className="nav-item"
                                            >
                                                <Link
                                                    style={{ justifyContent: isOpen ? 'center' : 'normal' }}
                                                    className={
                                                        location.pathname === '/users' || location.pathname === '/userlist'
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    to="/users"
                                                >
                                                    <img
                                                        src={isHovered1 || location.pathname === '/users' || location.pathname === '/userlist' ? Users : UsersBlack}
                                                        className='siderbar-icons'
                                                        alt="Dashboard Icon"
                                                    />
                                                    <p
                                                        style={{
                                                            display: isOpen ? 'none' : 'block',
                                                            color: (isHovered1 || location.pathname === '/users' || location.pathname === '/userlist') ? '#ffffff' : '#6F6B7D',
                                                        }}
                                                        className='navitems mb-0 sidetext'
                                                    >
                                                        Users
                                                    </p>
                                                </Link>
                                            </li>
                                            <li
                                                onClick={() => setExpaned(!expanded)}
                                                onMouseEnter={() => setIsHovered2(true)}
                                                onMouseLeave={() => setIsHovered2(false)}
                                                className="nav-item"
                                            >
                                                <Link
                                                    style={{ justifyContent: isOpen ? 'center' : 'normal' }}
                                                    className={
                                                        location.pathname === '/funds-basket'
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    to="/funds-basket"
                                                >
                                                    <img
                                                        src={isHovered2 || location.pathname === '/funds-basket' ? FundsBasket : FundsBasketBlack}
                                                        className='siderbar-icons'
                                                        alt="Dashboard Icon"
                                                    />
                                                    <p
                                                        style={{
                                                            display: isOpen ? 'none' : 'block',
                                                            color: (isHovered2 || location.pathname === '/funds-basket') ? '#ffffff' : '#6F6B7D',
                                                        }}
                                                        className='navitems mb-0 sidetext'
                                                    >
                                                        Funds Basket
                                                    </p>
                                                </Link>
                                            </li>
                                            <li
                                                onClick={handleLogout}
                                                onMouseEnter={() => setIsHovered3(true)}
                                                onMouseLeave={() => setIsHovered3(false)}
                                                className="nav-item"
                                            >
                                                <button
                                                    style={{ justifyContent: isOpen ? 'center' : 'normal' }}
                                                    className={
                                                        location.pathname === ''
                                                            ? 'nav-link active'
                                                            : 'nav-link'
                                                    }
                                                    
                                                >
                                                    <img
                                                        src={isHovered3 || location.pathname === '' ? Logout : Logout}
                                                        className='siderbar-icons'
                                                        alt="Dashboard Icon"
                                                        style={{ opacity: 50 }}
                                                    />
                                                    <p
                                                        style={{
                                                            display: isOpen ? 'none' : 'block',
                                                            color: (isHovered3 || location.pathname === '') ? '#ffffff' : '#6F6B7D',
                                                        }}
                                                        className='navitems mb-0 sidetext'
                                                    >
                                                        Logout
                                                    </p>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div>
                                    <div style={{ display: isOpen ? "none" : "block" }}>
                                        <div onClick={() => setExpaned(!expanded)}>
                                            <div className={"nav-link d-flex justify-content-start align-self-center mt-3"} style={{ marginLeft: 15, marginRight: 15, marginBottom: 20 }}>
                                                <p className='mb-0'>@ 2024 DigiRM</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </AnimatePresence>
                </>
            </div >
        </>
    )
}

export default Sidebar