import logo from './logo.svg';
import Login from './pages/login/Login';
import './App.scss';
import { Routes, Route, useLocation } from 'react-router-dom';
import { routes } from './utils/routes';
import Sidebar from './components/sidebar/Sidebar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const location = useLocation();
  const isExcludedPage = ['/login', '/'].includes(location.pathname);
  return (
    <>
      <div className='main-app' style={{ display: 'flex', height: '100%', backgroundColor: '#F8F7FA' }}>
        {isExcludedPage ? null : <Sidebar />}
        <div className='main-content' style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Routes>
            {routes.map((route, key) => (
              <Route key={key} {...route} />
            ))}
          </Routes>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default App;
